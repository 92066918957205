import React from 'react'
// import Brand from '../../../assets/svg/brand.svg'
import { Container, Headline } from './hero.css'

const Hero = () => (
  <Container>
    <Headline>Welcome to jasebousbooks</Headline>
  </Container>
)

export default Hero;